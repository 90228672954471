import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData, paymentDetails } from '../../core/api/dashboard.sevice';
declare var $: any;
import { ProviderData } from '@angular/core/src/view';
import { forEach } from '@angular/router/src/utils/collection';
import { IMyDpOptions } from 'mydatepicker';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'klassakt-viewcartpayment',
  templateUrl: './viewcartpayment.component.html'
})
export class KlassaktViewcartpaymentComponent extends KlassaktDashboardComponent implements OnInit{
  ccavenuecheque: any;
  public selectedPayment: string = '';
  private sub: any;
  private selectedImageUrl: string;
  tempcount: number = 0;
  previousval: number = 0;
  activeColor: string = '';
  selected: any;

  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd mmm yyyy',
    showTodayBtn: false,
  };
  public async selectcolor(clr, pId, cId) {
    var clrda = undefined;

    var ColorRes = await this._apiService.post('api/addColors', {
      OptionDetailId: clr.optionDetailsid, OptionMasterId: clr.optionMasterId, ProductOptionMasterId: clr.productOptionMasterid, ProductOptionDetailId: clr.productOptionDetailId, ProductId: pId, cartID: cId
    })

    if (ColorRes == 1) {
      clrda = await this._apiService.post('api/activeColor', {
        OptionDetailId: clr.optionDetailsid, ProductOptionDetailId: clr.productOptionDetailId, ProductId: pId, cartID: cId
      })
      this.activeColor = clrda;
      this.toastr.successToastr('Added Color', 'Success!', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      //this.loadpagedata();


    }
    else {
      this.toastr.errorToastr('Please try again!', '', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
    }
    
    

  }

  async ngOnInit() {
    let navigae = true;
    if (this.account && this.account.students) {
      for (let std of this.account.students) {
        if (std.cart) {
          navigae = false;
          break;
        }
      }
    }
    if (navigae) {
      this.goToPage('/dashboard/(dashboardRouter:viewcart)');
      return;
    }
    
    await super.ngOnInit();
     
    if (this.account.students && this.account.students[0].cart && this.account.students[0].cart.paymentModes.length == 1) {
      this.selectedPayment = this.account.students[0].cart.paymentModes.indexOf(5) >= 0 ? "Online" : (this.account.students[0].cart.paymentModes.indexOf(3) >= 0 ? "Cash" : "Cheque");

    }  
  }

  protected async onAfterStudentsLoaded() {
   //this.account.paymentDetails = new paymentDetails();
    this.account.highlightStudent = false;
    this.account.highlightStudent = false;
  }

  public onShowpaymentSection( seletedTab) {
 
    this.selectedPayment = seletedTab;
  }
  async loadcartpagedata() {
    let cart = await this._apiService.post<any[]>('api/getViewcartDetails', { AccountID: this._userService.data.id });

    for (let i in cart) {
      let student = this.getStudent(cart[i].userID);
      student.cart = cart[i];
    }
    
  }


  //async deletecart(userId: number) {

  //  var data = await this._apiService.post('/deleteAllcart', { userID: userId, parentUserID: this._userService.data.id })
  //  this.toastr.successToastr('Item(s) Successfully Deleted from Cart', 'Success!', { dismiss: 'click' });
  //  this.loadcartpagedata();
  //}

  //async deletecartItem(userId: number, cartID: string) {

  //  var data = await this._apiService.post('/deleteOnecartitem', { userID: userId, parentUserID: this._userService.data.id, cartID: cartID })
  //  this.toastr.successToastr('Item Successfully Deleted from Cart', 'Success!', { dismiss: 'click' });
  //  this.loadcartpagedata();
  //}

  public setCartitemQuant(prodcartID, prodQuant, currentQuant) {
    this.cartid = prodcartID;
    this.quantity = prodQuant;
    if (currentQuant < prodQuant) {
      $("#Quantitychange").modal('show');
    } else {
      this.updatecartQuantity(prodcartID, prodQuant, 0);
    }
  }
  public async updatecartQuantity(cartid: string, quantity: number, IsNewSet: number) {
    $("#Quantitychange").modal('hide');
    let status = await this._apiService.post('api/updateproductcartQuantity', { cartID: cartid, Quantity: quantity, IsNewSet: IsNewSet });
    this.toastr.successToastr('Quantity updated successfully', 'Success', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true});
    this._router.navigateByUrl('/dashboard/(dashboardRouter:viewcart)');

  }
  private grandtotal = 0;
  private currency=0;
  public getCartTotal() {
    this.grandtotal = 0;
    for (let i = 0; i < this.students.length; i++) {
      if (this.students[i].cart && this.students[i].cart.grandTotalamount) {
        this.grandtotal = this.grandtotal + this.students[i].cart.grandTotalamount;
        this.currency = this.students[i].cart.currencyName;
      }
    }
    
    return this.grandtotal + " " + this.currency ;
  }

  async deleteConfirm(userId: number, cartID: string) {
    if (cartID == "0") {
      var data = await this._apiService.post('api/deleteAllcart', { userID: userId, parentUserID: this._userService.data.id })
    } else {
      var data = await this._apiService.post('api/deleteOnecartitem', { userID: userId, parentUserID: this._userService.data.id, cartID: cartID })
    }
    $("#confirmDelete").modal('hide');
    this.toastr.successToastr('Item Successfully Deleted from Cart', 'Success!', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true});
    this.loadcartpagedata();
  }

 

  public async dismissModal() {
    $("#Quantitychange").modal('hide');
    this.loadcartpagedata();
  }


  public async submitcartp() {
    if (this.selectedImages.length != this.requiredImages) {
      this.toastr.errorToastr('Please select required ' + this.requiredImages + ' image(s) before save', 'Mandatory', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true  });
    } else {
      let status = await this._apiService.post('api/savePreviewDetails', { userID: this.userId, parentUserID: this._userService.data.id, cartID: this.cartid, ProductId: this.productID, schoolpackgID: this.schoolpackgID, selectedImages: this.selectedImages });
      this.toastr.successToastr('Images Saved Successfully', 'Success ', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      $("#PreviewModal").modal('hide');
      this.loadcartpagedata();
    }
  }

  private isOrderConfirmClicked: boolean = false;
  public async cartPayment() {
    if (this.account.email == undefined || this.account.email.trim() == '' || this.account.mobile == undefined || this.account.mobile.trim() == '')
    {
      this.toastr.errorToastr('Email Id and Contact Number is Required.', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
    }
 
    this.account.paymentDetails.AccountID = this._userService.data.id;
    this.account.paymentDetails.paymentMode = this.selectedPayment;
    this.account.paymentDetails.email = this.account.email;
    this.account.paymentDetails.mobile = this.account.mobile;

    if (this.account.paymentDetails.PaymentDate != null && this.account.paymentDetails.PaymentDate != undefined)
    this.account.paymentDetails.PaymentChequeDate = this.account.paymentDetails.PaymentDate.formatted;
    if (this.selectedPayment == undefined || this.selectedPayment == '') {
      this.toastr.errorToastr('Please Select Payment Mode', 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
      return;
      
    }

    if (this.isOrderConfirmClicked) return;
    this.isOrderConfirmClicked = true;

    let result = await this._apiService.post<any>('api/payment', this.account.paymentDetails);
    
    if (this.selectedPayment == "Online" && result && result.action) {
      //let appFrm = $('#appFrame').contents();
      let frmUid = 'frm'+new Date().getTime();
      $('body').append("<form id='" + frmUid + "' method='POST' action='" + result.action + "'><input type='hidden' name='encRequest' value='" + result.req + "' /><input type='hidden' name='access_code' value='" + result.accessCode + "' /></form>");
      $('form#' + frmUid).submit();
      //this._userService.cacheAccountData(this.account);
      //appFrm.find("#" + frmUid).submit();
      //$('#appFrame').attr('src', result.action + '&encRequest=' + result.req + '&access_code=' + result.accessCode );
      //$('#angularApp').attr('style', 'display:none');
      //$('#appFrame').removeAttr('style').attr('style','width:100%;height:500px');
    }
    else if (result.toString() == 'Payment Done Successfully') {
        this.toastr.successToastr('Order Placed Successfully', 'Success', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
        this.goToPage('/dashboard/(dashboardRouter:orderSummary)');
      }
      else {
        this.toastr.errorToastr(result.toString(), 'Error', { timeOut: 15000, position: 'top-full-width1', showCloseButton: true });
        this.isOrderConfirmClicked = false;
      }
  }
  public checkIstrueCol2(initialVal, tempCountdata) {
    if (initialVal != this.previousval) {
      this.tempcount = 0;
      tempCountdata = initialVal;
      this.previousval = initialVal;
    }


    if (initialVal == tempCountdata)
      return true;
    let tempNumber: number;
    tempNumber = initialVal;
    for (let j = initialVal; j < tempCountdata && tempNumber <= tempCountdata; j++) {
      if (tempNumber == tempCountdata) {
        if (this.NonCourselImageCount == tempCountdata) {
          this.tempcount = 0;
        }
        return true;
      }
      tempNumber += 4;
    }
    if (this.NonCourselImageCount == tempCountdata) {
      this.tempcount = 0;
    }
    return false;

  }
  public increamentval() {

    this.tempcount += 1;
    return this.tempcount;
  }
  public valueReset() {
    this.tempcount = 0;
    return this.tempcount;
  }
  protected get tempCountval() {
    return this.tempcount;
  }


  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  } 
   
  }
   

