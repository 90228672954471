import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
declare var $: any;

@Injectable()
export class KlassaktLoaderService {

  public show():void {
    setTimeout(() => {
      $('#divBusyIndicator').show();
    }, 1000);
  }

  public hide(): void {
    setTimeout(() => { $('#divBusyIndicator').hide(); }, 1000);
  }

}

export class Account {
  public students: Student[];
  public orders:   any ;
  public selectedStudent: Student;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public picPath: string;
  public addNewKid: StudentDetailToAdd;
  public paymentDetails: paymentDetails;
  public userId: number;
  public email: string;
  public timerEndDate: any;
  public mobile: string;
  public highlightStudent: boolean = true;
  public relation: string;
  public fullName: string;
  public waterMarkUrl: string;
  public isheartPink: boolean = false;
}

export class SupportDetail {
  public parentName: string;
  public contactNo: string;
  public mailId: string;
  public objsupportCategoryList: listData<any>;
  public studentFullName: string;
  public supportrequestDeatllist: listData<any>;
  public doalreadyRegistered: string;
}

export class StudentDetailToAdd {
  public parentName: string;
  public relation     :string;
  public studentName: string;
  public studentID: string;
  public schoolName   :string;
  public sectionList: listData<any>;
  public doAlreadyRegistered: string;
  public studentNameToEnter: string;
}

export class Student {
  public userID: number;
  public firstName: string;
  public lastName: string;
  public schoolId: number;
  public schoolName: string;
  public classId: number;
  public className: string;
  public sectionId: number;
  public sectionName: string;
  public currencyCode: string;
  public favImageWithoutCorselCount: number;
  public NonfavImageWithoutCorselCount: number;
  public photos: listData<any>;
  public products: listData<any>;
  public packages: listData<any>;
  public orders: any;
  public downloads: listData<any>;
  public offers: listData<any>;
  public cart: any;
  public supportDetail: SupportDetail;
  public timeStamp: any;
  public isSibling: number;
  public EventName: string;
  public QrCode: string;


}

export class listData<T> {
  public lastLoaded: Date;
  public list: T[];
  public noOfItems: number;
  public get isItemsPending(): boolean {
    return !this.list || !this.noOfItems || this.noOfItems != this.list.length;
  }
}

@Injectable()
export class KlassaktDashboardService {

  public selectedStudent: Student;
  public account: Account;
  public DashboardSection: any;
  isheartpink: any;
  public waterMarkUrl: any;
}

export class paymentDetails {

  public ChequeGivenBy: string;
  public ChequeNumber: string
  public PaymentChequeDate: string;
  public BankName: string;
  public totalPrice: string;
  public schoolCurrency: string;
  public paymentMode: string;
  public AccountID: string;
  public PaymentDate: any;
  public email: string;
  public mobile: string;

}
