import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService,KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData } from '../../core/api/dashboard.sevice';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'klassakt-packages',
  templateUrl: './packages.component.html' 
})
export class KlassaktPackagesComponent extends KlassaktDashboardComponent implements OnInit {
  

  protected async onVisible() { 
    if (this.account && this.selectedStudent && this.selectedStudent.packages == undefined) {
      let isSibling = this.selectedStudent.isSibling == 1 ? 1 : 0;
      this.selectedStudent.packages = await this._apiService.post<listData<any>>('api/getPackageDetails', { schoolID: this.selectedStudent.schoolId, isSibling });
    }
  }
  
  protected async onAfterStudentsLoaded() {
    await this.onVisible();
    //this.goToPage('/dashboard');
  }
  
  async ngOnInit() {
    await super.ngOnInit();
    if (this.account && this.selectedStudent && this.selectedStudent.packages == undefined) {
      let isSibling = this.selectedStudent.isSibling == 1 ? 1 : 0;
      this.selectedStudent.packages = await this._apiService.post<listData<any>>('api/getPackageDetails', { schoolID: this.selectedStudent.schoolId, isSibling });
    }
  }
  adjustCollapse(packageID: string)
  {
    return '#' + packageID;
  }
  //async addPackageCarts(packageID: string) {

  //  var data = await this._apiService.post('api/addproductcart', { userID: this.selectedStudent.userID, ProductId: '00000000-0000-0000-0000-000000000000', packageID: packageID, parentUserID: this._userService.data.id })   
  //    this.toastr.successToastr('Item Successfully Added To Cart', 'Success!', { dismiss: 'click' }); 
  //  }
  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }
}
