import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KlassaktDashboardComponent } from './../dashboard'
import { KlassaktUserService, KlassaktStorageService, KlassaktApiService } from '../../core';
import { KlassaktDashboardService, Student, listData } from '../../core/api/dashboard.sevice';
import { ProviderData } from '@angular/core/src/view';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'klassakt-products',
  templateUrl: './products.component.html'
})
export class KlassaktProductsComponent extends KlassaktDashboardComponent implements OnInit {

  protected async onVisible() { 
    if (this.account && this.selectedStudent && this.selectedStudent.products == undefined) {

      let isSibling = this.selectedStudent.isSibling == 1 ? 1 : 0;
      this.selectedStudent.products = await this._apiService.post <listData<any>>('api/getProductDetails', { schoolID: this.selectedStudent.schoolId, isSibling: isSibling});
      
    }

  }
  protected async onAfterStudentsLoaded() {
   await this.onVisible();

  }

  async ngOnInit() {
    await super.ngOnInit();
    if (this.account && this.selectedStudent && this.selectedStudent.products == undefined) {

      let isSibling = this.selectedStudent.isSibling == 1 ? 1 : 0;
      this.selectedStudent.products = await this._apiService.post<listData<any>>('api/getProductDetails', { schoolID: this.selectedStudent.schoolId, isSibling: isSibling });

    }
  }

  public showProduct(RefID: string) {
    this._router.navigate(['/', 'dashboard', { outlets: { dashboardRouter: ['product', { 'productId': RefID}] } }]);
    
  }
  //async addPackageCarts(ProductId: string) {

  //  var data = await this._apiService.post('api/addproductcart', { userID: this.selectedStudent.userID, ProductId: ProductId, packageID: '00000000-0000-0000-0000-000000000000', parentUserID: this._userService.data.id  })
  //  this.toastr.successToastr('Item Successfully Added To Cart', 'Success!', { dismiss: 'click' });
  //}
  public constructor(
    protected _userService: KlassaktUserService,
    protected _router: Router,
    protected _apiService: KlassaktApiService,
    protected _dashboardService: KlassaktDashboardService,
    protected _route: ActivatedRoute,
    protected toastr: ToastrManager,
    public _sanitizer: DomSanitizer) {
    super(_userService, _router, _apiService, _dashboardService, _route, toastr, _sanitizer);
  }
}
